/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface InternalAmoTokenClientIdGet$Params {

/**
 * Client id
 */
  client_id: string;

/**
 * Force refresh of tokens
 */
  refresh?: boolean;
}

export function internalAmoTokenClientIdGet(http: HttpClient, rootUrl: string, params: InternalAmoTokenClientIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'amo_domain': string;
'amo_id': number;
'platform': 'amocrm' | 'kommo';
'access_token': string;
'token_user_id': number;
}>> {
  const rb = new RequestBuilder(rootUrl, internalAmoTokenClientIdGet.PATH, 'get');
  if (params) {
    rb.path('client_id', params.client_id, {});
    rb.query('refresh', params.refresh, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'amo_domain': string;
      'amo_id': number;
      'platform': 'amocrm' | 'kommo';
      'access_token': string;
      'token_user_id': number;
      }>;
    })
  );
}

internalAmoTokenClientIdGet.PATH = '/internal/amo-token/{client_id}';
