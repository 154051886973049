/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface ApiWebhooksIdRequestsGet$Params {

/**
 * Webhook id
 */
  id: string;

/**
 * Amount of records to return, maximum is 100
 */
  limit?: number;

/**
 * Offset to start search from
 */
  offset?: number;
}

export function apiWebhooksIdRequestsGet(http: HttpClient, rootUrl: string, params: ApiWebhooksIdRequestsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<{
'request_id': string;
'created_at': string;
'body': string;
'ip': string;
'content_type': string;
}>>> {
  const rb = new RequestBuilder(rootUrl, apiWebhooksIdRequestsGet.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<{
      'request_id': string;
      'created_at': string;
      'body': string;
      'ip': string;
      'content_type': string;
      }>>;
    })
  );
}

apiWebhooksIdRequestsGet.PATH = '/api/webhooks/{id}/requests';
