/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountAmoData } from '../models/account-amo-data';
import { apiAccountGet } from '../fn/operations/api-account-get';
import { ApiAccountGet$Params } from '../fn/operations/api-account-get';
import { apiGroupsGet } from '../fn/operations/api-groups-get';
import { ApiGroupsGet$Params } from '../fn/operations/api-groups-get';
import { apiGroupsIdDelete } from '../fn/operations/api-groups-id-delete';
import { ApiGroupsIdDelete$Params } from '../fn/operations/api-groups-id-delete';
import { apiGroupsIdGet } from '../fn/operations/api-groups-id-get';
import { ApiGroupsIdGet$Params } from '../fn/operations/api-groups-id-get';
import { apiGroupsIdPatch } from '../fn/operations/api-groups-id-patch';
import { ApiGroupsIdPatch$Params } from '../fn/operations/api-groups-id-patch';
import { apiGroupsPost } from '../fn/operations/api-groups-post';
import { ApiGroupsPost$Params } from '../fn/operations/api-groups-post';
import { apiScenarioConstructorDataGet } from '../fn/operations/api-scenario-constructor-data-get';
import { ApiScenarioConstructorDataGet$Params } from '../fn/operations/api-scenario-constructor-data-get';
import { apiScenariosGet } from '../fn/operations/api-scenarios-get';
import { ApiScenariosGet$Params } from '../fn/operations/api-scenarios-get';
import { apiScenariosIdDelete } from '../fn/operations/api-scenarios-id-delete';
import { ApiScenariosIdDelete$Params } from '../fn/operations/api-scenarios-id-delete';
import { apiScenariosIdGet } from '../fn/operations/api-scenarios-id-get';
import { ApiScenariosIdGet$Params } from '../fn/operations/api-scenarios-id-get';
import { apiScenariosIdPatch } from '../fn/operations/api-scenarios-id-patch';
import { ApiScenariosIdPatch$Params } from '../fn/operations/api-scenarios-id-patch';
import { apiScenariosPost } from '../fn/operations/api-scenarios-post';
import { ApiScenariosPost$Params } from '../fn/operations/api-scenarios-post';
import { apiTokenGet } from '../fn/operations/api-token-get';
import { ApiTokenGet$Params } from '../fn/operations/api-token-get';
import { apiWebhooksGet } from '../fn/operations/api-webhooks-get';
import { ApiWebhooksGet$Params } from '../fn/operations/api-webhooks-get';
import { apiWebhooksIdDelete } from '../fn/operations/api-webhooks-id-delete';
import { ApiWebhooksIdDelete$Params } from '../fn/operations/api-webhooks-id-delete';
import { apiWebhooksIdGet } from '../fn/operations/api-webhooks-id-get';
import { ApiWebhooksIdGet$Params } from '../fn/operations/api-webhooks-id-get';
import { apiWebhooksIdPatch } from '../fn/operations/api-webhooks-id-patch';
import { ApiWebhooksIdPatch$Params } from '../fn/operations/api-webhooks-id-patch';
import { apiWebhooksIdRequestsGet } from '../fn/operations/api-webhooks-id-requests-get';
import { ApiWebhooksIdRequestsGet$Params } from '../fn/operations/api-webhooks-id-requests-get';
import { apiWebhooksPost } from '../fn/operations/api-webhooks-post';
import { ApiWebhooksPost$Params } from '../fn/operations/api-webhooks-post';
import { internalAmoAccountDataPost } from '../fn/operations/internal-amo-account-data-post';
import { InternalAmoAccountDataPost$Params } from '../fn/operations/internal-amo-account-data-post';
import { internalAmoTokenClientIdGet } from '../fn/operations/internal-amo-token-client-id-get';
import { InternalAmoTokenClientIdGet$Params } from '../fn/operations/internal-amo-token-client-id-get';
import { localeLangGet } from '../fn/operations/locale-lang-get';
import { LocaleLangGet$Params } from '../fn/operations/locale-lang-get';
import { ScenarioEventTypeGroup } from '../models/scenario-event-type-group';
import { ScenarioFunction } from '../models/scenario-function';
import { ScenarioGroupResponse } from '../models/scenario-group-response';
import { ScenarioResponse } from '../models/scenario-response';
import { ScenarioSchema } from '../models/scenario-schema';
import { ScenarioVariable } from '../models/scenario-variable';
import { WebhookResponse } from '../models/webhook-response';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiScenariosGet()` */
  static readonly ApiScenariosGetPath = '/api/scenarios';

  /**
   * Lists scenarios.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenariosGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosGet$Response(params?: ApiScenariosGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ScenarioResponse>>> {
    return apiScenariosGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists scenarios.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiScenariosGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosGet(params?: ApiScenariosGet$Params, context?: HttpContext): Observable<Array<ScenarioResponse>> {
    return this.apiScenariosGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ScenarioResponse>>): Array<ScenarioResponse> => r.body)
    );
  }

  /** Path part for operation `apiScenariosPost()` */
  static readonly ApiScenariosPostPath = '/api/scenarios';

  /**
   * Creates a scenario.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenariosPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiScenariosPost$Response(params?: ApiScenariosPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ScenarioResponse>> {
    return apiScenariosPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a scenario.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiScenariosPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiScenariosPost(params?: ApiScenariosPost$Params, context?: HttpContext): Observable<ScenarioResponse> {
    return this.apiScenariosPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScenarioResponse>): ScenarioResponse => r.body)
    );
  }

  /** Path part for operation `apiScenariosIdGet()` */
  static readonly ApiScenariosIdGetPath = '/api/scenarios/{id}';

  /**
   * Retieves a scenario.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenariosIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosIdGet$Response(params: ApiScenariosIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ScenarioResponse>> {
    return apiScenariosIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retieves a scenario.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiScenariosIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosIdGet(params: ApiScenariosIdGet$Params, context?: HttpContext): Observable<ScenarioResponse> {
    return this.apiScenariosIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScenarioResponse>): ScenarioResponse => r.body)
    );
  }

  /** Path part for operation `apiScenariosIdDelete()` */
  static readonly ApiScenariosIdDeletePath = '/api/scenarios/{id}';

  /**
   * Deletes a scenario.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenariosIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosIdDelete$Response(params: ApiScenariosIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiScenariosIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes a scenario.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiScenariosIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosIdDelete(params: ApiScenariosIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiScenariosIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiScenariosIdPatch()` */
  static readonly ApiScenariosIdPatchPath = '/api/scenarios/{id}';

  /**
   * Updates a scenario.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenariosIdPatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiScenariosIdPatch$Response(params: ApiScenariosIdPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ScenarioResponse>> {
    return apiScenariosIdPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates a scenario.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiScenariosIdPatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiScenariosIdPatch(params: ApiScenariosIdPatch$Params, context?: HttpContext): Observable<ScenarioResponse> {
    return this.apiScenariosIdPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScenarioResponse>): ScenarioResponse => r.body)
    );
  }

  /** Path part for operation `apiGroupsGet()` */
  static readonly ApiGroupsGetPath = '/api/groups';

  /**
   * Lists scenario groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGroupsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGroupsGet$Response(params?: ApiGroupsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ScenarioGroupResponse>>> {
    return apiGroupsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists scenario groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiGroupsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGroupsGet(params?: ApiGroupsGet$Params, context?: HttpContext): Observable<Array<ScenarioGroupResponse>> {
    return this.apiGroupsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ScenarioGroupResponse>>): Array<ScenarioGroupResponse> => r.body)
    );
  }

  /** Path part for operation `apiGroupsPost()` */
  static readonly ApiGroupsPostPath = '/api/groups';

  /**
   * Creates a scenario group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGroupsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiGroupsPost$Response(params?: ApiGroupsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ScenarioGroupResponse>> {
    return apiGroupsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a scenario group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiGroupsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiGroupsPost(params?: ApiGroupsPost$Params, context?: HttpContext): Observable<ScenarioGroupResponse> {
    return this.apiGroupsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScenarioGroupResponse>): ScenarioGroupResponse => r.body)
    );
  }

  /** Path part for operation `apiGroupsIdGet()` */
  static readonly ApiGroupsIdGetPath = '/api/groups/{id}';

  /**
   * Retieves a scenario group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGroupsIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGroupsIdGet$Response(params: ApiGroupsIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ScenarioGroupResponse>> {
    return apiGroupsIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retieves a scenario group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiGroupsIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGroupsIdGet(params: ApiGroupsIdGet$Params, context?: HttpContext): Observable<ScenarioGroupResponse> {
    return this.apiGroupsIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScenarioGroupResponse>): ScenarioGroupResponse => r.body)
    );
  }

  /** Path part for operation `apiGroupsIdDelete()` */
  static readonly ApiGroupsIdDeletePath = '/api/groups/{id}';

  /**
   * Deletes a scenario group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGroupsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGroupsIdDelete$Response(params: ApiGroupsIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiGroupsIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes a scenario group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiGroupsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGroupsIdDelete(params: ApiGroupsIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiGroupsIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiGroupsIdPatch()` */
  static readonly ApiGroupsIdPatchPath = '/api/groups/{id}';

  /**
   * Updates a scenario group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGroupsIdPatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiGroupsIdPatch$Response(params: ApiGroupsIdPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ScenarioGroupResponse>> {
    return apiGroupsIdPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates a scenario group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiGroupsIdPatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiGroupsIdPatch(params: ApiGroupsIdPatch$Params, context?: HttpContext): Observable<ScenarioGroupResponse> {
    return this.apiGroupsIdPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScenarioGroupResponse>): ScenarioGroupResponse => r.body)
    );
  }

  /** Path part for operation `apiWebhooksGet()` */
  static readonly ApiWebhooksGetPath = '/api/webhooks';

  /**
   * Lists webhooks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWebhooksGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWebhooksGet$Response(params?: ApiWebhooksGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WebhookResponse>>> {
    return apiWebhooksGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists webhooks.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWebhooksGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWebhooksGet(params?: ApiWebhooksGet$Params, context?: HttpContext): Observable<Array<WebhookResponse>> {
    return this.apiWebhooksGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WebhookResponse>>): Array<WebhookResponse> => r.body)
    );
  }

  /** Path part for operation `apiWebhooksPost()` */
  static readonly ApiWebhooksPostPath = '/api/webhooks';

  /**
   * Creates a webhook.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWebhooksPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiWebhooksPost$Response(params?: ApiWebhooksPost$Params, context?: HttpContext): Observable<StrictHttpResponse<WebhookResponse>> {
    return apiWebhooksPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a webhook.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWebhooksPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiWebhooksPost(params?: ApiWebhooksPost$Params, context?: HttpContext): Observable<WebhookResponse> {
    return this.apiWebhooksPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebhookResponse>): WebhookResponse => r.body)
    );
  }

  /** Path part for operation `apiWebhooksIdGet()` */
  static readonly ApiWebhooksIdGetPath = '/api/webhooks/{id}';

  /**
   * Retieves a webhook.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWebhooksIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWebhooksIdGet$Response(params: ApiWebhooksIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<WebhookResponse>> {
    return apiWebhooksIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retieves a webhook.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWebhooksIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWebhooksIdGet(params: ApiWebhooksIdGet$Params, context?: HttpContext): Observable<WebhookResponse> {
    return this.apiWebhooksIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebhookResponse>): WebhookResponse => r.body)
    );
  }

  /** Path part for operation `apiWebhooksIdDelete()` */
  static readonly ApiWebhooksIdDeletePath = '/api/webhooks/{id}';

  /**
   * Deletes a webhook.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWebhooksIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWebhooksIdDelete$Response(params: ApiWebhooksIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiWebhooksIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes a webhook.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWebhooksIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWebhooksIdDelete(params: ApiWebhooksIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiWebhooksIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiWebhooksIdPatch()` */
  static readonly ApiWebhooksIdPatchPath = '/api/webhooks/{id}';

  /**
   * Updates a webhook.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWebhooksIdPatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiWebhooksIdPatch$Response(params: ApiWebhooksIdPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<WebhookResponse>> {
    return apiWebhooksIdPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates a webhook.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWebhooksIdPatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiWebhooksIdPatch(params: ApiWebhooksIdPatch$Params, context?: HttpContext): Observable<WebhookResponse> {
    return this.apiWebhooksIdPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebhookResponse>): WebhookResponse => r.body)
    );
  }

  /** Path part for operation `apiWebhooksIdRequestsGet()` */
  static readonly ApiWebhooksIdRequestsGetPath = '/api/webhooks/{id}/requests';

  /**
   * Returns last 5 requests sent to this webhook.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWebhooksIdRequestsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWebhooksIdRequestsGet$Response(params: ApiWebhooksIdRequestsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<{
'request_id': string;
'created_at': string;
'body': string;
'ip': string;
'content_type': string;
}>>> {
    return apiWebhooksIdRequestsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns last 5 requests sent to this webhook.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWebhooksIdRequestsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWebhooksIdRequestsGet(params: ApiWebhooksIdRequestsGet$Params, context?: HttpContext): Observable<Array<{
'request_id': string;
'created_at': string;
'body': string;
'ip': string;
'content_type': string;
}>> {
    return this.apiWebhooksIdRequestsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
'request_id': string;
'created_at': string;
'body': string;
'ip': string;
'content_type': string;
}>>): Array<{
'request_id': string;
'created_at': string;
'body': string;
'ip': string;
'content_type': string;
}> => r.body)
    );
  }

  /** Path part for operation `apiAccountGet()` */
  static readonly ApiAccountGetPath = '/api/account';

  /**
   * Returns scenario constructor data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet$Response(params?: ApiAccountGet$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'client_id': string;
'webhook_url': string;
'amo': AccountAmoData;
}>> {
    return apiAccountGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns scenario constructor data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet(params?: ApiAccountGet$Params, context?: HttpContext): Observable<{
'client_id': string;
'webhook_url': string;
'amo': AccountAmoData;
}> {
    return this.apiAccountGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'client_id': string;
'webhook_url': string;
'amo': AccountAmoData;
}>): {
'client_id': string;
'webhook_url': string;
'amo': AccountAmoData;
} => r.body)
    );
  }

  /** Path part for operation `apiTokenGet()` */
  static readonly ApiTokenGetPath = '/api/token';

  /**
   * Returns a new auth token for current authorized client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTokenGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTokenGet$Response(params?: ApiTokenGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<{
'token': string;
'expires_in': number;
}>>> {
    return apiTokenGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a new auth token for current authorized client.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTokenGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTokenGet(params?: ApiTokenGet$Params, context?: HttpContext): Observable<Array<{
'token': string;
'expires_in': number;
}>> {
    return this.apiTokenGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
'token': string;
'expires_in': number;
}>>): Array<{
'token': string;
'expires_in': number;
}> => r.body)
    );
  }

  /** Path part for operation `apiScenarioConstructorDataGet()` */
  static readonly ApiScenarioConstructorDataGetPath = '/api/scenario-constructor-data';

  /**
   * Returns scenario constructor data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenarioConstructorDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenarioConstructorDataGet$Response(params?: ApiScenarioConstructorDataGet$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'variables': Array<ScenarioVariable>;
'functions': Array<ScenarioFunction>;
'event_types': Array<ScenarioEventTypeGroup>;
'schemas': Array<ScenarioSchema>;
}>> {
    return apiScenarioConstructorDataGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns scenario constructor data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiScenarioConstructorDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenarioConstructorDataGet(params?: ApiScenarioConstructorDataGet$Params, context?: HttpContext): Observable<{
'variables': Array<ScenarioVariable>;
'functions': Array<ScenarioFunction>;
'event_types': Array<ScenarioEventTypeGroup>;
'schemas': Array<ScenarioSchema>;
}> {
    return this.apiScenarioConstructorDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'variables': Array<ScenarioVariable>;
'functions': Array<ScenarioFunction>;
'event_types': Array<ScenarioEventTypeGroup>;
'schemas': Array<ScenarioSchema>;
}>): {
'variables': Array<ScenarioVariable>;
'functions': Array<ScenarioFunction>;
'event_types': Array<ScenarioEventTypeGroup>;
'schemas': Array<ScenarioSchema>;
} => r.body)
    );
  }

  /** Path part for operation `localeLangGet()` */
  static readonly LocaleLangGetPath = '/locale/{lang}';

  /**
   * Returns a requested locale file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `localeLangGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  localeLangGet$Response(params: LocaleLangGet$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return localeLangGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a requested locale file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `localeLangGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  localeLangGet(params: LocaleLangGet$Params, context?: HttpContext): Observable<{
}> {
    return this.localeLangGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `internalAmoTokenClientIdGet()` */
  static readonly InternalAmoTokenClientIdGetPath = '/internal/amo-token/{client_id}';

  /**
   * Returns client amo token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `internalAmoTokenClientIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  internalAmoTokenClientIdGet$Response(params: InternalAmoTokenClientIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'amo_domain': string;
'amo_id': number;
'platform': 'amocrm' | 'kommo';
'access_token': string;
'token_user_id': number;
}>> {
    return internalAmoTokenClientIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns client amo token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `internalAmoTokenClientIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  internalAmoTokenClientIdGet(params: InternalAmoTokenClientIdGet$Params, context?: HttpContext): Observable<{
'amo_domain': string;
'amo_id': number;
'platform': 'amocrm' | 'kommo';
'access_token': string;
'token_user_id': number;
}> {
    return this.internalAmoTokenClientIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'amo_domain': string;
'amo_id': number;
'platform': 'amocrm' | 'kommo';
'access_token': string;
'token_user_id': number;
}>): {
'amo_domain': string;
'amo_id': number;
'platform': 'amocrm' | 'kommo';
'access_token': string;
'token_user_id': number;
} => r.body)
    );
  }

  /** Path part for operation `internalAmoAccountDataPost()` */
  static readonly InternalAmoAccountDataPostPath = '/internal/amo-account-data';

  /**
   * Return account data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `internalAmoAccountDataPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internalAmoAccountDataPost$Response(params?: InternalAmoAccountDataPost$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'account_id': number;
'platform': string;
'info': {
};
}>> {
    return internalAmoAccountDataPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Return account data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `internalAmoAccountDataPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internalAmoAccountDataPost(params?: InternalAmoAccountDataPost$Params, context?: HttpContext): Observable<{
'account_id': number;
'platform': string;
'info': {
};
}> {
    return this.internalAmoAccountDataPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'account_id': number;
'platform': string;
'info': {
};
}>): {
'account_id': number;
'platform': string;
'info': {
};
} => r.body)
    );
  }

}
